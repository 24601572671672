import FoolPng from "app/assets/images/cards/tarotV1/fool.png";
import MagicianPng from "app/assets/images/cards/tarotV1/magician.png";
import HighPriestessPng from "app/assets/images/cards/tarotV1/highPriestess.png";
import EmpressPng from "app/assets/images/cards/tarotV1/empress.png";
import EmperorPng from "app/assets/images/cards/tarotV1/emperor.png";
import HierophantPng from "app/assets/images/cards/tarotV1/hierophant.png";
import LoversPng from "app/assets/images/cards/tarotV1/lovers.png";
import ChariotPng from "app/assets/images/cards/tarotV1/chariot.png";
import StrengthPng from "app/assets/images/cards/tarotV1/strength.png";
import HermitPng from "app/assets/images/cards/tarotV1/hermit.png";
import WheelOfFortunePng from "app/assets/images/cards/tarotV1/wheelOfFortune.png";
import JusticePng from "app/assets/images/cards/tarotV1/justice.png";
import HangedManPng from "app/assets/images/cards/tarotV1/hangedMan.png";
import DeathPng from "app/assets/images/cards/tarotV1/death.png";
import TemperancePng from "app/assets/images/cards/tarotV1/temperance.png";
import DevilPng from "app/assets/images/cards/tarotV1/devil.png";
import TowerPng from "app/assets/images/cards/tarotV1/tower.png";
import StarPng from "app/assets/images/cards/tarotV1/star.png";
import MoonPng from "app/assets/images/cards/tarotV1/moon.png";
import SunPng from "app/assets/images/cards/tarotV1/sun.png";
import JudgementPng from "app/assets/images/cards/tarotV1/judgement.png";
import WorldPng from "app/assets/images/cards/tarotV1/world.png";

// Minor Arcana - Ace to Ten for each suit
import AceOfCupsPng from "app/assets/images/cards/tarotV1/aceOfCups.png";
import AceOfPentaclesPng from "app/assets/images/cards/tarotV1/aceOfPentacles.png";
import AceOfSwordsPng from "app/assets/images/cards/tarotV1/aceOfSwords.png";
import AceOfWandsPng from "app/assets/images/cards/tarotV1/aceOfWands.png";

import TwoOfCupsPng from "app/assets/images/cards/tarotV1/2OfCups.png";
import TwoOfPentaclesPng from "app/assets/images/cards/tarotV1/2OfPentacles.png";
import TwoOfSwordsPng from "app/assets/images/cards/tarotV1/2OfSwords.png";
import TwoOfWandsPng from "app/assets/images/cards/tarotV1/2OfWands.png";

import ThreeOfCupsPng from "app/assets/images/cards/tarotV1/3OfCups.png";
import ThreeOfPentaclesPng from "app/assets/images/cards/tarotV1/3OfPentacles.png";
import ThreeOfSwordsPng from "app/assets/images/cards/tarotV1/3OfSwords.png";
import ThreeOfWandsPng from "app/assets/images/cards/tarotV1/3OfWands.png";

import FourOfCupsPng from "app/assets/images/cards/tarotV1/4OfCups.png";
import FourOfPentaclesPng from "app/assets/images/cards/tarotV1/4OfPentacles.png";
import FourOfSwordsPng from "app/assets/images/cards/tarotV1/4OfSwords.png";
import FourOfWandsPng from "app/assets/images/cards/tarotV1/4OfWands.png";

import FiveOfCupsPng from "app/assets/images/cards/tarotV1/5OfCups.png";
import FiveOfPentaclesPng from "app/assets/images/cards/tarotV1/5OfPentacles.png";
import FiveOfSwordsPng from "app/assets/images/cards/tarotV1/5OfSwords.png";
import FiveOfWandsPng from "app/assets/images/cards/tarotV1/5OfWands.png";

import SixOfCupsPng from "app/assets/images/cards/tarotV1/6OfCups.png";
import SixOfPentaclesPng from "app/assets/images/cards/tarotV1/6OfPentacles.png";
import SixOfSwordsPng from "app/assets/images/cards/tarotV1/6OfSwords.png";
import SixOfWandsPng from "app/assets/images/cards/tarotV1/6OfWands.png";

import SevenOfCupsPng from "app/assets/images/cards/tarotV1/7OfCups.png";
import SevenOfPentaclesPng from "app/assets/images/cards/tarotV1/7OfPentacles.png";
import SevenOfSwordsPng from "app/assets/images/cards/tarotV1/7OfSwords.png";
import SevenOfWandsPng from "app/assets/images/cards/tarotV1/7OfWands.png";

import EightOfCupsPng from "app/assets/images/cards/tarotV1/8OfCups.png";
import EightOfPentaclesPng from "app/assets/images/cards/tarotV1/8OfPentacles.png";
import EightOfSwordsPng from "app/assets/images/cards/tarotV1/8OfSwords.png";
import EightOfWandsPng from "app/assets/images/cards/tarotV1/8OfWands.png";

import NineOfCupsPng from "app/assets/images/cards/tarotV1/9OfCups.png";
import NineOfPentaclesPng from "app/assets/images/cards/tarotV1/9OfPentacles.png";
import NineOfSwordsPng from "app/assets/images/cards/tarotV1/9OfSwords.png";
import NineOfWandsPng from "app/assets/images/cards/tarotV1/9OfWands.png";

import TenOfCupsPng from "app/assets/images/cards/tarotV1/10OfCups.png";
import TenOfPentaclesPng from "app/assets/images/cards/tarotV1/10OfPentacles.png";
import TenOfSwordsPng from "app/assets/images/cards/tarotV1/10OfSwords.png";
import TenOfWandsPng from "app/assets/images/cards/tarotV1/10OfWands.png";

// Minor Arcana - Page, Knight, Queen, King
import PageOfCupsPng from "app/assets/images/cards/tarotV1/pageOfCups.png";
import PageOfPentaclesPng from "app/assets/images/cards/tarotV1/pageOfPentacles.png";
import PageOfSwordsPng from "app/assets/images/cards/tarotV1/pageOfSwords.png";
import PageOfWandsPng from "app/assets/images/cards/tarotV1/pageOfWands.png";

import KnightOfCupsPng from "app/assets/images/cards/tarotV1/knightOfCups.png";
import KnightOfPentaclesPng from "app/assets/images/cards/tarotV1/knightOfPentacles.png";
import KnightOfSwordsPng from "app/assets/images/cards/tarotV1/knightOfSwords.png";
import KnightOfWandsPng from "app/assets/images/cards/tarotV1/knightOfWands.png";

import QueenOfCupsPng from "app/assets/images/cards/tarotV1/queenOfCups.png";
import QueenOfPentaclesPng from "app/assets/images/cards/tarotV1/queenOfPentacles.png";
import QueenOfSwordsPng from "app/assets/images/cards/tarotV1/queenOfSwords.png";
import QueenOfWandsPng from "app/assets/images/cards/tarotV1/queenOfWands.png";

import KingOfCupsPng from "app/assets/images/cards/tarotV1/kingOfCups.png";
import KingOfPentaclesPng from "app/assets/images/cards/tarotV1/kingOfPentacles.png";
import KingOfSwordsPng from "app/assets/images/cards/tarotV1/kingOfSwords.png";
import KingOfWandsPng from "app/assets/images/cards/tarotV1/kingOfWands.png";

export const tarotCardDictionary = [
  // Major Arcana
  {
    name: "The Fool",
    number: 0,
    image: FoolPng,
    description:
      "Taking a leap of faith in love, even if you don’t know where it leads.",
  },
  {
    name: "The Magician",
    number: 1,
    image: MagicianPng,
    description: "You have all the tools to create a magical relationship.",
  },
  {
    name: "The High Priestess",
    number: 2,
    image: HighPriestessPng,
    description: "Trust your gut feelings about your relationship.",
  },
  {
    name: "The Empress",
    number: 3,
    image: EmpressPng,
    description:
      "Love is growing, and there’s lots of care and nurturing here.",
  },
  {
    name: "The Emperor",
    number: 4,
    image: EmperorPng,
    description: "Stay strong and protect what you love.",
  },
  {
    name: "The Hierophant",
    number: 5,
    image: HierophantPng,
    description:
      "Your love life may involve traditions or a deeper commitment.",
  },
  {
    name: "The Lovers",
    number: 6,
    image: LoversPng,
    description: "A deep connection is forming, and it feels like true love.",
  },
  {
    name: "The Chariot",
    number: 7,
    image: ChariotPng,
    description:
      "Move forward with determination and focus on what you want in love.",
  },
  {
    name: "Strength",
    number: 8,
    image: StrengthPng,
    description:
      "Be patient and gentle with your partner to build a lasting love.",
  },
  {
    name: "The Hermit",
    number: 9,
    image: HermitPng,
    description: "Take some time alone to understand what you need from love.",
  },
  {
    name: "Wheel of Fortune",
    number: 10,
    image: WheelOfFortunePng,
    description: "Changes are coming to your love life, so go with the flow.",
  },
  {
    name: "Justice",
    number: 11,
    image: JusticePng,
    description:
      "Be fair and honest in your relationship to keep love balanced.",
  },
  {
    name: "The Hanged Man",
    number: 12,
    image: HangedManPng,
    description:
      "See things from your partner’s point of view for a better understanding.",
  },
  {
    name: "Death",
    number: 13,
    image: DeathPng,
    description: "An ending is leading to a new beginning in love.",
  },
  {
    name: "Temperance",
    number: 14,
    image: TemperancePng,
    description:
      "Balance and patience are important for your relationship to thrive.",
  },
  {
    name: "The Devil",
    number: 15,
    image: DevilPng,
    description:
      "Be careful of unhealthy habits or attachments in your relationship.",
  },
  {
    name: "The Tower",
    number: 16,
    image: TowerPng,
    description:
      "A sudden change may shake up your love life, but it will lead to growth.",
  },
  {
    name: "The Star",
    number: 17,
    image: StarPng,
    description: "Stay hopeful because love is on its way.",
  },
  {
    name: "The Moon",
    number: 18,
    image: MoonPng,
    description:
      "Things might be confusing in love, so be careful not to jump to conclusions.",
  },
  {
    name: "The Sun",
    number: 19,
    image: SunPng,
    description: "Happiness and joy are shining on your love life.",
  },
  {
    name: "Judgement",
    number: 2,
    image: JudgementPng,
    description: "It’s time to make a big decision about your relationship.",
  },
  {
    name: "The World",
    number: 21,
    image: WorldPng,
    description:
      "You’ve reached a happy ending in love, feeling whole and complete.",
  },

  // Minor Arcana - Wands
  {
    name: "Ace of Wands",
    number: 22,
    image: AceOfWandsPng,
    description: "A new spark is lighting up your love life.",
  },
  {
    name: "Two of Wands",
    number: 23,
    image: TwoOfWandsPng,
    description:
      "You’re planning your future in love—maybe with someone special.",
  },
  {
    name: "Three of Wands",
    number: 24,
    image: ThreeOfWandsPng,
    description:
      "Love is expanding, and you’re ready for more adventures together.",
  },
  {
    name: "Four of Wands",
    number: 25,
    image: FourOfWandsPng,
    description: "Celebration and happiness in your love, maybe even marriage.",
  },
  {
    name: "Five of Wands",
    number: 26,
    image: FiveOfWandsPng,
    description:
      "There may be some arguments, but they can help you grow closer.",
  },
  {
    name: "Six of Wands",
    number: 27,
    image: SixOfWandsPng,
    description:
      "Your relationship feels victorious, and you’re proud of each other.",
  },
  {
    name: "Seven of Wands",
    number: 28,
    image: SevenOfWandsPng,
    description: "Stand up for your relationship, even when others doubt it.",
  },
  {
    name: "Eight of Wands",
    number: 29,
    image: EightOfWandsPng,
    description: "Things are moving fast in love, and it’s exciting!",
  },
  {
    name: "Nine of Wands",
    number: 3,
    image: NineOfWandsPng,
    description: "You’ve been through a lot, but love is worth protecting.",
  },
  {
    name: "Ten of Wands",
    number: 31,
    image: TenOfWandsPng,
    description:
      "Love feels heavy right now, so try to share the load with your partner.",
  },
  {
    name: "Page of Wands",
    number: 32,
    image: PageOfWandsPng,
    description: "A fun, flirty message is coming your way.",
  },
  {
    name: "Knight of Wands",
    number: 33,
    image: KnightOfWandsPng,
    description: "Someone adventurous is bringing passion to your love life.",
  },
  {
    name: "Queen of Wands",
    number: 34,
    image: QueenOfWandsPng,
    description: "You’re attracting love by being confident and yourself.",
  },
  {
    name: "King of Wands",
    number: 35,
    image: KingOfWandsPng,
    description:
      "A strong leader is coming into your life who will inspire love.",
  },

  // Minor Arcana - Cups
  {
    name: "Ace of Cups",
    number: 36,
    image: AceOfCupsPng,
    description: "A new love is beginning, full of deep feelings.",
  },
  {
    name: "Two of Cups",
    number: 37,
    image: TwoOfCupsPng,
    description: "A perfect partnership where both people feel the same way.",
  },
  {
    name: "Three of Cups",
    number: 38,
    image: ThreeOfCupsPng,
    description: "Celebrating love with friends or happy times together.",
  },
  {
    name: "Four of Cups",
    number: 39,
    image: FourOfCupsPng,
    description:
      "You might be missing out on love if you’re too focused on what you don’t have.",
  },
  {
    name: "Five of Cups",
    number: 4,
    image: FiveOfCupsPng,
    description: "There’s sadness in love, but don’t forget what’s still good.",
  },
  {
    name: "Six of Cups",
    number: 41,
    image: SixOfCupsPng,
    description:
      "A sweet love from the past or remembering happy times with someone.",
  },
  {
    name: "Seven of Cups",
    number: 42,
    image: SevenOfCupsPng,
    description: "You have lots of choices in love, so choose wisely.",
  },
  {
    name: "Eight of Cups",
    number: 43,
    image: EightOfCupsPng,
    description:
      "Walking away from a love that isn’t making you happy anymore.",
  },
  {
    name: "Nine of Cups",
    number: 44,
    image: NineOfCupsPng,
    description: "Your love life feels fulfilling, like a wish come true.",
  },
  {
    name: "Ten of Cups",
    number: 45,
    image: TenOfCupsPng,
    description: "A happy family and feeling totally loved.",
  },
  {
    name: "Page of Cups",
    number: 46,
    image: PageOfCupsPng,
    description: "Someone is sending you a sweet message, like a love letter.",
  },
  {
    name: "Knight of Cups",
    number: 47,
    image: KnightOfCupsPng,
    description: "A charming person is coming in with a romantic offer.",
  },
  {
    name: "Queen of Cups",
    number: 48,
    image: QueenOfCupsPng,
    description: "Your caring and kind heart is attracting deep love.",
  },
  {
    name: "King of Cups",
    number: 49,
    image: KingOfCupsPng,
    description:
      "A loving, emotionally mature person is bringing stability to your love life.",
  },

  // Minor Arcana - Swords
  {
    name: "Ace of Swords",
    number: 5,
    image: AceOfSwordsPng,
    description:
      "Clarity and truth are needed to fix things in your relationship.",
  },
  {
    name: "Two of Swords",
    number: 51,
    image: TwoOfSwordsPng,
    description:
      "You have a choice to make in love, but you might be avoiding it.",
  },
  {
    name: "Three of Swords",
    number: 52,
    image: ThreeOfSwordsPng,
    description: "Heartbreak is painful, but it will help you learn and grow.",
  },
  {
    name: "Four of Swords",
    number: 53,
    image: FourOfSwordsPng,
    description: "Take a break to rest and heal your heart.",
  },
  {
    name: "Five of Swords",
    number: 54,
    image: FiveOfSwordsPng,
    description: "Arguments or hurtful words could be damaging love right now.",
  },
  {
    name: "Six of Swords",
    number: 55,
    image: SixOfSwordsPng,
    description: "Moving away from hard times and into calmer, better love.",
  },
  {
    name: "Seven of Swords",
    number: 56,
    image: SevenOfSwordsPng,
    description: "Be careful of lies or secrets in your relationship.",
  },
  {
    name: "Eight of Swords",
    number: 57,
    image: EightOfSwordsPng,
    description: "You feel stuck in love, but it’s mostly in your mind.",
  },
  {
    name: "Nine of Swords",
    number: 58,
    image: NineOfSwordsPng,
    description: "Worry and fear about love may be keeping you up at night.",
  },
  {
    name: "Ten of Swords",
    number: 59,
    image: TenOfSwordsPng,
    description:
      "An ending in love might hurt, but it’s time to let go and heal.",
  },
  {
    name: "Page of Swords",
    number: 6,
    image: PageOfSwordsPng,
    description: "Someone is curious about you and may be watching from afar.",
  },
  {
    name: "Knight of Swords",
    number: 61,
    image: KnightOfSwordsPng,
    description:
      "Love is rushing in quickly, but be careful it’s not too hasty.",
  },
  {
    name: "Queen of Swords",
    number: 62,
    image: QueenOfSwordsPng,
    description: "Be honest and clear about your feelings in love.",
  },
  {
    name: "King of Swords",
    number: 63,
    image: KingOfSwordsPng,
    description:
      "Use logic and fairness to make decisions in your relationship.",
  },

  // Minor Arcana - Pentacles
  {
    name: "Ace of Pentacles",
    number: 64,
    image: AceOfPentaclesPng,
    description:
      "A new, solid beginning is forming in love, like a stable relationship.",
  },
  {
    name: "Two of Pentacles",
    number: 65,
    image: TwoOfPentaclesPng,
    description: "You’re trying to balance love and other parts of your life.",
  },
  {
    name: "Three of Pentacles",
    number: 66,
    image: ThreeOfPentaclesPng,
    description: "Working together makes your relationship stronger.",
  },
  {
    name: "Four of Pentacles",
    number: 67,
    image: FourOfPentaclesPng,
    description: "Don’t hold on too tightly; love needs freedom to grow.",
  },
  {
    name: "Five of Pentacles",
    number: 68,
    image: FiveOfPentaclesPng,
    description:
      "Feeling left out in love, but someone may be there for you if you look closer.",
  },
  {
    name: "Six of Pentacles",
    number: 69,
    image: SixOfPentaclesPng,
    description: "Love is about giving and receiving equally.",
  },
  {
    name: "Seven of Pentacles",
    number: 7,
    image: SevenOfPentaclesPng,
    description: "You’ve put in the effort, now be patient as your love grows.",
  },
  {
    name: "Eight of Pentacles",
    number: 71,
    image: EightOfPentaclesPng,
    description:
      "Focus on improving your relationship by working at it every day.",
  },
  {
    name: "Nine of Pentacles",
    number: 72,
    image: NineOfPentaclesPng,
    description: "Enjoy being independent and loving yourself first.",
  },
  {
    name: "Ten of Pentacles",
    number: 73,
    image: TenOfPentaclesPng,
    description: "Building a lasting love and a stable family together.",
  },
  {
    name: "Page of Pentacles",
    number: 74,
    image: PageOfPentaclesPng,
    description: "A small but important new offer in love is coming.",
  },
  {
    name: "Knight of Pentacles",
    number: 75,
    image: KnightOfPentaclesPng,
    description: "Someone reliable and hardworking is bringing steady love.",
  },
  {
    name: "Queen of Pentacles",
    number: 76,
    image: QueenOfPentaclesPng,
    description: "Nurture and care for your partner to make love bloom.",
  },
  {
    name: "King of Pentacles",
    number: 77,
    image: KingOfPentaclesPng,
    description:
      "A mature person who values stability and loyalty is offering true love.",
  },
];
