import { useBreakpoint } from "app/utils/useBreakpoint";
import classNames from "classnames";
import * as React from "react";
import MoreReadingsLogo from "app/assets/images/moreReadingsLogo.svg";
import { genderDictionary } from "app/dictionaries/genderDictionary";
import { useScreen1 } from "./useScreen1";
import { TitleDescription } from "app/quiz/components/titleDescription";

export interface Screen1Props {
  gender: string | null;
  setGender: (gender: string) => void;
  setCurrentScreen: (screen: number) => void;
}

export const Screen1: React.FC<Screen1Props> = ({
  gender,
  setGender,
  setCurrentScreen,
}) => {
  const { handleGenderSelect } = useScreen1({
    setGender,
    setCurrentScreen,
  });

  const { isBelowMd, isAboveMd } = useBreakpoint("md");

  return (
    <div className="flex flex-col items-center">
      <img
        src={MoreReadingsLogo}
        alt="More Readings Logo"
        className={classNames("w-36 mb-[16%]", {})}
      />
      <h1
        className={classNames(
          "text-[#F5E7C8] font-public text-center w-full max-w-[628px]",
          {
            "text-2xl mb-[18%]": isAboveMd,
            "mb-[10%]": isBelowMd,
          }
        )}
      >
        Improve relationships using astrology. Get your detailed personalized
        psychic reading.
      </h1>
      <TitleDescription
        title="Personal assessment quizlet"
        description="Select your gender:"
      />
      <div
        className={classNames("flex justify-center items-center", {
          "gap-16": isAboveMd,
          "gap-4": isBelowMd,
        })}
      >
        {Object.entries(genderDictionary).map(([key, { title, image }]) => {
          const isGenderSelected = gender === key;
          return (
            <div className="flex flex-col gap-5" key={key}>
              <div
                className={classNames(
                  "w-24 h-24 border rounded cursor-pointer",
                  isGenderSelected
                    ? "border-[1px] border-solid border-[COLOR.PE] bg-[COLOR.HME9] overflow-hidden"
                    : "border-transparent bg-transparent"
                )}
                onClick={() => handleGenderSelect(key)}
              >
                <img src={image} alt={title} height={"100%"} width={"100%"} />
              </div>
              <button
                onClick={() => handleGenderSelect(key)}
                className="font-aquino text-[18px] text-[COLOR.PE]"
              >
                {title}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
