import * as React from "react";
import { useQuiz2 } from "./useQuiz2";
import { RelationshipStatusTypes } from "app/quiz/useQuiz";
import classNames from "classnames";
import quizStyles from "app/quiz/quiz.module.css";
import { Button } from "@nextui-org/react";
import { startTransition } from "react";
import { useBreakpoint } from "app/utils/useBreakpoint";

// Preload critical assets for faster LCP
import YellowLeftArrow from "app/assets/icons/yellowLeftArrow.svg";
import MoreReadingsLogoSvg from "app/assets/images/moreReadingsLogo.svg";
import StarPng from "app/assets/images/star.png";
import MoonPng from "app/assets/images/moon.png";
import RomComPng from "app/assets/images/romCom.png";
import { compact } from "lodash";
import { Loading } from "app/components/loading/loading";
import { Screen1 } from "./screens/screen1/screen1";

// Lazy loading for non-essential screens and components
const DateOfBirthScreen = React.lazy(() =>
  import("./screens/dateOfBirthScreen/dateOfBirthScreen").then((module) => ({
    default: module.DateOfBirthScreen,
  }))
);
const SunSignScreen = React.lazy(() =>
  import("./screens/sunSignScreen/sunSignScreen").then((module) => ({
    default: module.SunSignScreen,
  }))
);
const EducationScreen = React.lazy(() =>
  import("app/quiz/components/educationScreen").then((module) => ({
    default: module.EducationScreen,
  }))
);
const TarotCardScreen = React.lazy(() =>
  import("./screens/tarotCardScreen/tarotCardScreen").then((module) => ({
    default: module.TarotCardScreen,
  }))
);
const EmailScreen = React.lazy(() =>
  import("./screens/emailScreen/emailScreen").then((module) => ({
    default: module.EmailScreen,
  }))
);
const WeHaveHelped = React.lazy(() =>
  import("./screens/weHaveHelped/weHaveHelped").then((module) => ({
    default: module.WeHaveHelped,
  }))
);
const ChoosePriceScreen = React.lazy(() =>
  import("./screens/choosePriceScreen/choosePriceScreen").then((module) => ({
    default: module.ChoosePriceScreen,
  }))
);
const ConversionScreen = React.lazy(() =>
  import("./screens/conversionScreen/conversionScreen").then((module) => ({
    default: module.ConversionScreen,
  }))
);
const ListScreen = React.lazy(() =>
  import("app/quiz/components/listScreen").then((module) => ({
    default: module.ListScreen,
  }))
);
const DeviceScreen = React.lazy(() =>
  import("./screens/deviceScreen/deviceScreen").then((module) => ({
    default: module.DeviceScreen,
  }))
);
const MoonProgress = React.lazy(() =>
  import("./component/moonProgress").then((module) => ({
    default: module.MoonProgress,
  }))
);

export interface Quiz2Props {}

// Helper function to determine which screen to render
const getScreen = ({
  currentScreenIndex,
  appendAnswerToParams,
  setCurrentScreenIndex,
  hideDeviceScreen,
}: any) => {
  const screens = [
    <Screen1
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <ListScreen
      title={""}
      description={"What is your relationship status?"}
      questions={[
        {
          leadingEmoji: "❤️",
          title: "Single",
          value: 0,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "💕",
          title: "In a relationship",
          value: 1,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "💍",
          title: "Married",
          value: 2,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "💔",
          title: "Complicated",
          value: 3,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
        {
          leadingEmoji: "🤔",
          title: "Unsure/Other",
          value: 4,
          onQuestionClick: (value: RelationshipStatusTypes) =>
            appendAnswerToParams({
              key: "relationshipStatus",
              value,
              nextPageIndex: currentScreenIndex + 1,
            }),
        },
      ]}
    />,
    <DateOfBirthScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <SunSignScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
      setCurrentScreenIndex={setCurrentScreenIndex}
    />,
    <EducationScreen
      img={StarPng}
      description="Everyday astrology is based on your sun sign, which shows your main personality and how you act. But your moon sign shows your feelings, and your rising sign is how people see you, so there's more to learn about who you are!"
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <TarotCardScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <EmailScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <WeHaveHelped
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    <ChoosePriceScreen
      currentScreenIndex={currentScreenIndex}
      appendAnswerToParams={appendAnswerToParams}
    />,
    !hideDeviceScreen && (
      <DeviceScreen
        appendAnswerToParams={appendAnswerToParams}
        currentScreenIndex={currentScreenIndex}
      />
    ),
    <ConversionScreen />,
  ];

  return compact(screens)[currentScreenIndex];
};

// Main Quiz component
export const Quiz2: React.FC<Quiz2Props> = ({}) => {
  const {
    currentScreenIndex,
    appendAnswerToParams,
    setCurrentScreenIndex,
    goBack,
    hideDeviceScreen,
  } = useQuiz2();

  const { isBelowXxs } = useBreakpoint("xxs");

  return (
    <div className="flex flex-col max-w-192 mobile:max-w-[313px] items-center">
      <React.Suspense fallback={<Loading />}>
        <div
          className={classNames("mb-8", {
            hidden: currentScreenIndex === 0 || currentScreenIndex > 20,
          })}
        >
          {/* Only render MoonProgress when not on the initial screen */}
          {currentScreenIndex !== 0 && (
            <MoonProgress
              currentIndex={currentScreenIndex}
              totalPages={20}
              dashCount={isBelowXxs ? 40 : 50}
              activeIndexCheckpoints={[0, 4, 8, 19]}
            />
          )}
        </div>

        {currentScreenIndex !== 0 && currentScreenIndex < 19 && (
          <Button
            isIconOnly
            className={classNames(
              "bg-transparent rounded !p-2 border-0 absolute self-center top-11 left-1/4 mobile:top-10 mobile:left-2",
              quizStyles.animatedBtnGradient
            )}
            onClick={() => {
              // Wrap state updates that may suspend in startTransition
              startTransition(() => {
                goBack();
              });
            }}
            variant="faded"
          >
            <img src={YellowLeftArrow} alt="back" />
          </Button>
        )}

        <div className="h-full">
          {getScreen({
            currentScreenIndex,
            appendAnswerToParams,
            setCurrentScreenIndex,
            hideDeviceScreen,
          })}
        </div>

        {currentScreenIndex !== 0 && (
          <div
            className={classNames("mobile:scale-75 mobile:bottom-5", {
              "relative my-10": currentScreenIndex === 14,
              "mb-5 bottom-0": currentScreenIndex !== 14,
            })}
          >
            <img
              src={MoreReadingsLogoSvg}
              alt="more readings"
              width="100"
              height="100"
            />
          </div>
        )}
      </React.Suspense>
    </div>
  );
};
