import * as React from "react";
import { useBreakpoint } from "app/utils/useBreakpoint";
import MoreReadingsLogo from "app/assets/images/moreReadingsLogo.svg";
import classNames from "classnames";
import { TitleDescription } from "app/quiz/components/titleDescription";
import { genderDictionary } from "app/dictionaries/genderDictionary";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Path } from "app/path";

export interface Screen1Props {
  currentScreenIndex: number;
  appendAnswerToParams: ({ key, value, nextPageIndex }: any) => void;
}

export const Screen1: React.FC<Screen1Props> = ({
  currentScreenIndex,
  appendAnswerToParams,
}) => {
  const { isBelowMd, isAboveMd } = useBreakpoint("md");
  const { gender } = useParams();

  return (
    <div className="flex flex-col items-center">
      <img
        src={MoreReadingsLogo}
        alt="More Readings Logo"
        className={classNames("mb-[10%] mobile:mb-[20%]", {})}
      />
      <p
        className={classNames(
          "text-[#F5E7C8] text-2xl font-public text-center max-w-[628px] mb-[15%] mobile:mb-[20%] mobile:text-medium"
        )}
      >
        Improve relationships using astrology. Get your detailed personalized
        psychic reading.
      </p>
      <TitleDescription
        title="Personal assessment quizlet"
        description="Select your gender:"
      />
      <div
        className={classNames("flex justify-center items-center mb-10", {
          "gap-16": isAboveMd,
          "gap-4": isBelowMd,
        })}
      >
        {Object.entries(genderDictionary).map(([key, { title, image }]) => {
          const isGenderSelected = gender === key;
          return (
            <div className="flex flex-col gap-5" key={key}>
              <div
                className={classNames(
                  "w-24 h-24 border rounded cursor-pointer",
                  isGenderSelected
                    ? "border-[1px] border-solid border-[COLOR.PE] bg-[COLOR.HME9] overflow-hidden"
                    : "border-transparent bg-transparent"
                )}
                onClick={() =>
                  appendAnswerToParams({
                    key: "gender",
                    value: key,
                    nextPageIndex: currentScreenIndex + 1,
                  })
                }
              >
                <img src={image} alt={title} height={"100%"} width={"100%"} />
              </div>
              <button
                onClick={() =>
                  appendAnswerToParams({
                    key: "gender",
                    value: key,
                    nextPageIndex: currentScreenIndex + 1,
                  })
                }
                className="font-aquino text-[18px] text-[COLOR.PE]"
              >
                {title}
              </button>
            </div>
          );
        })}
      </div>
      <div className="mt-10 flex flex-col items-center h-full">
        <p
          className={classNames("text-[#8f8671]", {
            "text-sm": isBelowMd,
          })}
        >
          By continuing, I agree to More Readings's
        </p>
        <div className="flex gap-1">
          <Link
            className={classNames("text-[#8f8671] underline", {
              "text-sm": isBelowMd,
            })}
            to={Path.POLICIES}
          >
            Privacy policy
          </Link>
          <p
            className={classNames("text-[#8f8671]", {
              "text-sm": isBelowMd,
            })}
          >
            and
          </p>
          <Link
            className={classNames("text-[#8f8671] underline", {
              "text-sm": isBelowMd,
            })}
            to={Path.TERMS}
          >
            Terms of Use.
          </Link>
        </div>
      </div>
    </div>
  );
};
