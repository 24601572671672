import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import { Rollbar } from "app/rollbar/rollbar";

// Initialize PostHog with specific options
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY!, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  disable_session_recording: process.env.NODE_ENV !== "production", // Enable session recording in production
  capture_pageview: process.env.NODE_ENV === "production", // Capture page views
  autocapture: process.env.NODE_ENV === "production", // Enable automatic event capturing in production
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Rollbar>
    <PostHogProvider client={posthog}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </PostHogProvider>
  </Rollbar>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
