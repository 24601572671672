import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Path } from "app/path";
import { NotFound } from "app/notFound/notFound";
import { Home } from "app/home/home";
import { Terms } from "app/legal/terms";
import { Policy } from "app/legal/policy";
import { Tiktok } from "app/social/tiktok";
import { ProfileCreationInvite } from "app/relationshipProfiles/profileCreationInvite/profileCreationInvite";
import { Quiz } from "app/quiz/quiz";

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path={Path.HOME} element={<Home />} />
      <Route path={Path.TERMS} element={<Terms />} />
      <Route path={Path.POLICIES} element={<Policy />} />
      <Route path={Path.TIKTOK} element={<Tiktok />} />
      <Route
        path={Path.PROFILE_CREATION_INVITE}
        element={<ProfileCreationInvite />}
      />
      <Route path={Path.QUIZ} element={<Quiz />} />
      <Route
        path={Path.QUIZ_REDIRECT}
        element={<Navigate to="/quiz/quiz2" replace />}
      />
    </Routes>
  );
};
