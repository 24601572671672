import MalePng from "app/assets/genders/male.webp";
import FemalePng from "app/assets/genders/female.webp";
import NonBinaryPng from "app/assets/genders/nonBinary.webp";
import MaleVideo from "app/assets/genders/male.mp4";
import FemaleVideo from "app/assets/genders/female.mp4";
import NonBinaryVideo from "app/assets/genders/nonBinary.mp4";
import MaleIconPng from "app/assets/genders/maleIcon.png";
import FemaleIconPng from "app/assets/genders/femaleIcon.png";
import NonBinaryIconPng from "app/assets/genders/nonBinaryIcon.png";

export const GenderDictionaryEnum = {
  MALE: "male",
  FEMALE: "female",
  NON_BINARY: "non_binary",
};

export const genderDictionary = {
  [GenderDictionaryEnum.MALE]: {
    title: "Male",
    video: MaleVideo,
    image: MalePng,
    icon: MaleIconPng,
  },
  [GenderDictionaryEnum.FEMALE]: {
    title: "Female",
    video: FemaleVideo,
    image: FemalePng,
    icon: FemaleIconPng,
  },
  [GenderDictionaryEnum.NON_BINARY]: {
    title: "Non Binary",
    video: NonBinaryVideo,
    image: NonBinaryPng,
    icon: NonBinaryIconPng,
  },
};
