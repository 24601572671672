import { Path } from "app/path";
import { useQuery } from "app/utils/useQuery";
import * as React from "react";
import { useEffect } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import CryptoJS from "crypto-js";

export const useQuiz2 = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const hideDeviceScreen = query.get("hds") === "true";
  const [currentScreenIndex, setCurrentScreenIndex] = React.useState(0);
  const baseRoot = generatePath(Path.QUIZ, { type: "quiz2" });

  const appendAnswerToParams = ({ key, value, nextPageIndex }: any) => {
    if (key && value !== undefined && value !== null) {
      query.set(key, value);
    }

    if (nextPageIndex) {
      query.set("page", nextPageIndex + 1);
      navigate(`${baseRoot}?${query.toString()}`);
      setCurrentScreenIndex(nextPageIndex);
    }
  };

  const goBack = () => {
    query.set("page", currentScreenIndex.toString());
    navigate(`${baseRoot}?${query.toString()}`);
    setCurrentScreenIndex(currentScreenIndex - 1);
  };

  useEffect(() => {
    if (query.has("page")) {
      setCurrentScreenIndex(Number(query.get("page")) - 1);
    }
  }, []);

  useEffect(() => {
    const page = query.get("page") || "1";
    if (page === "1" && process.env.NODE_ENV === "production") {
      try {
        window.ttq.track("ViewContent", {
          contents: [
            {
              content_id: "quiz-2", // string. ID of the product. Example: "1077218".
              content_type: "product_group", // string. Either product or product_group.
              content_name: "Quiz 2", // string. The name of the page or product. Example: "shirt".
            },
          ],
          value: 0, // number. Value of the order or items sold. Example: 100.
          currency: "USD", // string. The 4217 currency code. Example: "USD".
        });
      } catch (err) {
        console.error("Error with TikTok Pixel track:", err);
      }
    }
  }, []);

  useEffect(() => {
    try {
      const email = query.get("email");
      const hashedEmail = CryptoJS.SHA256(email!).toString();
      if (process.env.NODE_ENV === "production") {
        window.ttq.identify({
          email: hashedEmail, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
        });
      }
    } catch (err) {
      console.error("Error identifying user with TikTok Pixel:", err);
    }
  }, [query.get("email")]);

  return {
    currentScreenIndex,
    setCurrentScreenIndex,
    appendAnswerToParams,
    goBack,
    hideDeviceScreen,
  };
};
