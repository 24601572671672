import * as React from "react";
import styles from "./legal.module.css";

export interface TermsProps {}

export const Terms: React.FC<TermsProps> = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.h1}>Terms of Service</h1>
      <p className={styles.p}>LAST UPDATED: APR 4TH, 2024</p>
      <p className={styles.p}>
        These Terms of Service (these “Terms”) apply to your access and use of:
        (1) our websites located at morereadings.com and shop.morereadings.com
        (the “Websites”); (2) our mobile applications (“Apps”); and (3) any
        services, content, and features made available by us through the
        Websites or the Apps (together with the Website and the Apps, our
        “Services”). In these Terms, “Tacit Social LLC.” “More Readings the
        “Company,” “we,” “us,” and “our” refer to More Readings and our
        affiliates, successors, and assigns; and “you” and “your” refer to any
        user of our Services.
      </p>

      <h2 className={styles.h2}>Acceptance of these terms</h2>

      <p className={styles.p}>
        Your access and use of our Services are subject at all times to these
        Terms and our Privacy Policy located at morereadings.com/privacy
        incorporated herein by reference. Please read these Terms carefully. By
        using the Services, you represent that you are legally able to enter
        into this agreement. By accessing or using our Services in any way or by
        clicking to accept or agree to the Terms when this option is made
        available to you, you agree to be bound by these Terms. If you do not
        agree to all the terms and conditions of these Terms, do not access or
        use our Services.
      </p>

      <p className={styles.p}>
        These terms contain a mandatory individual arbitration and class
        action/jury trial waiver provision that requires the use of arbitration
        on an individual basis to resolve disputes, rather than jury trials or
        class actions, and also limits the remedies available to you in the
        event of a dispute
      </p>

      <h2 className={styles.h2}>Unauthorized use by minors</h2>

      <p className={styles.p}>
        Our Services are not intended for children under the age of 13. If you
        are under 13, you are prohibited from creating an account or using the
        Services. If legally required, users between 13 and 18 years of age may
        use the Services only with permission and supervision of a parent or
        legal guardian. If you do not meet all of these requirements, you are
        prohibited from accessing or using the Services.
      </p>

      <h2 className={styles.h2}>Modification of the Terms</h2>

      <p className={styles.p}>
        We may revise these Terms from time to time at our sole discretion and
        without prior notice, subject to applicable law. The date of the last
        update is reflected at the beginning of these Terms. When we revise
        these Terms, we will post the revised version on the Services. You are
        free to decide whether or not to accept a revised version of these
        Terms, but accepting these Terms, as revised, is required for you to
        continue accessing or using the Services. If you do not agree to these
        Terms or any revised version of these Terms, your sole recourse is to
        terminate your access or use of the Services. Except as otherwise
        expressly stated by us, your access and use of the Services are subject
        to, and constitute your acceptance of, the version of these Terms in
        effect at the time of your access or use.
      </p>

      <h2 className={styles.h2}>Access and account security</h2>

      <p className={styles.p}>
        We're relentlessly improving our Services and creating new ones all the
        time. This means that we may add, stop updating, or remove features,
        products, content, or functionality, and we may also suspend or stop the
        Services altogether. We may take any of these actions at any time, and
        when we do, we will try to notify you beforehand - but this won't always
        be possible.
      </p>

      <p className={styles.p}>
        To access and use some of the Services, you may be required to provide
        certain information (“User Information”) and register for an account
        (“Account”). Our Privacy Policy governs our collection, use, storage,
        and disclosure of any personal information contained within User
        Information. You represent and warrant that all User Information you
        provide us from time to time in connection with the Services is
        truthful, accurate, current, and complete. You agree to promptly notify
        us of changes to you User Information by updating your Account on the
        Website or through the Apps.
      </p>

      <p className={styles.p}>
        You are responsible for any activity that occurs in your More Readings
        account, whether authorized or unauthorized by you. So it's important
        that you take appropriate steps to keep your account secure (e.g., using
        strong and passwords, keeping your password private, exercising caution
        when using public or shared devices). The Company will not be liable for
        any loss that you incur as a result of someone else using your account
        or password, either with or without your knowledge. You may be held
        liable for any losses incurred by Company, its affiliates, officers,
        directors, employees, consultants, agents, and representatives due to
        someone else's use of your account or password. Except as otherwise
        expressly stated in these Terms or required by applicable law, we are
        not responsible for any losses or damages arising out of the loss or
        theft of your username, password, or other security information. If you
        think that someone has gained access to your account, please reach out
        immediately to hello@support.morereadings.me
      </p>

      <h2 className={styles.h2}>Intellectual property rights</h2>

      <p className={styles.p}>
        The Services and all related content, features, and functionality
        (including, but not limited to, all information, software, text,
        displays, images, video and audio, and the design, selection, and
        arrangement thereof), are owned by Tacit Social LLC, its licensors, or
        other providers of such material and are protected by United States and
        international copyright, trademark, patent, trade secret, and other
        intellectual property or proprietary rights laws as applicable.
      </p>

      <p className={styles.p}>
        Subject to these Terms, you are permitted to access and use the Services
        for your personal, non-commercial use only. The More Readings name, the
        More Readings logo and all related names, logos, product and service
        names, designs, and slogans are trademarks of Tacit Social LLC. You must
        not use such marks without our prior written permission. You may not do
        any of the following (or enable anyone else to do so):
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          Copy, archive, download, upload, distribute, syndicate, broadcast,
          perform, display, print, monitor, make available, modify, or otherwise
          use any portion of the Services or the content on the Services except
          as set forth in these Terms;
        </li>
        <li className={styles.li}>
          Use the Services, any tools provided by the Services, or any content
          on the Services for any commercial purposes without obtaining our
          advance written consent; or violate or infringe More Readings's
          copyrights, trademarks, or other intellectual property rights.
        </li>
      </ul>
      <p className={styles.p}>
        If you'd like to use our brand assets in a way not covered by these
        guidelines, please reach out to us at hello@support.morereadings.me
      </p>

      <p className={styles.p}>
        If you violate these Terms, your right to access and use the Services
        will cease immediately, and you must, at our option, return or destroy
        any copies of the materials you have made. No right, title, or interest
        in or to the Services is transferred to you, and all rights not
        expressly granted are reserved by the Company. Any access or use of the
        Services not expressly permitted by these Terms is a breach of these
        Terms and may violate copyright, trademark, and other laws.
      </p>

      <h2 className={styles.h2}>Prohibited uses</h2>
      <p className={styles.p}>
        You may use the Services only for lawful purposes and in accordance with
        these Terms. If your access or use of the Services is prohibited by
        applicable law, then you are not authorized to access or use the
        Services. We are not responsible if you access or use the Services in
        any manner that violates applicable law. You agree not to use the
        Services:
      </p>
      <ul className={styles.ul}>
        <li className={styles.li}>
          In any way that violates any applicable federal, state, local, or
          international law or regulation (including, without limitation, any
          laws regarding the export of data or software to and from the United
          States or other countries);
        </li>
        <li className={styles.li}>
          For the purpose of exploiting, harming, or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content,
          asking for personally identifiable information or otherwise;
        </li>
        <li className={styles.li}>
          In a manner that violates the content standards set out in these Terms
          when you send, knowingly receive, upload, download, use, or re-use any
          material that violates these Terms or applicable law;
        </li>
        <li className={styles.li}>
          To transmit, or procure the sending of, any advertising or promotional
          material without our prior written consent, including any junk mail,
          spam or other solicitation;
        </li>
        <li className={styles.li}>
          To impersonate or attempt to impersonate More Readings, a More
          Readings employee, another user, or any other person or entity
          (including, without limitation, by using e-mail addresses or usernames
          associated with any of the foregoing); or
        </li>
        <li className={styles.li}>
          To engage in any other conduct that restricts or inhibits anyone's use
          or enjoyment of the Services, or which, as determined by us, may harm
          More Readings or users of the Services or expose them to liability
        </li>
      </ul>

      <h2 className={styles.h2}>Additionally, you agree not to:</h2>

      <ul className={styles.ul}>
        <li className={styles.li}>
          Use the Services in any manner that could disable, overburden, damage,
          or impair the Services or interfere with any other party's use of the
          Services, including their ability to engage in real time activities
          through the Services;
        </li>
        <li className={styles.li}>
          Use any robot, spider, or other automatic device, process, or means to
          access the Services for any purpose, including monitoring or copying
          any of the material available through the Services;
        </li>
        <li className={styles.li}>
          Use any device, software, or routine that interferes with the proper
          working of the Services;
        </li>
        <li className={styles.li}>
          Introduce any viruses, trojan horses, worms, logic bombs, or other
          material that is malicious or technologically harmful;
        </li>
        <li className={styles.li}>
          Attempt to gain unauthorized access to, interfere with, damage, or
          disrupt any parts of the Services, the servers on which the Services
          are stored, or any server, computer, or database connected to the
          Services;
        </li>
        <li className={styles.li}>
          Attack the Services via a denial-of-service attack or a distributed
          denial-of-service attack; or
        </li>
        <li className={styles.li}>
          Otherwise attempt to interfere with the proper working of the
          Services.
        </li>
      </ul>

      <h2 className={styles.h2}>Uploaded content</h2>

      <p className={styles.p}>
        Aspects of our Services let you create, upload, post, send, receive, and
        store content (collectively, “Uploaded Content”). Uploaded content must
        not:
      </p>
      <ul className={styles.ul}>
        <li className={styles.li}>
          Violate or infringe someone else's rights of publicity, privacy,
          copyright, trademark, or other intellectual property right;
        </li>
        <li className={styles.li}>
          Contain any material which is false, defamatory, libelous, obscene,
          harassing, threatening, discriminatory, bigoted, hateful, violent,
          vulgar, profane, pornographic or otherwise offensive, inappropriate,
          damaging, unlawful, disruptive or harmful;
        </li>
        <li className={styles.li}>
          Seek to harm or exploit children by exposing them to inappropriate
          content, asking for personally identifiable information or otherwise
        </li>
        <li className={styles.li}>
          Bully, harass, intimidate, defame, dox, spam, or solicit our users; or
        </li>
        <li className={styles.li}>
          Be otherwise objectionable or offensive as determined by us at our
          sole discretion.
        </li>
      </ul>

      <p className={styles.p}>
        Any Uploaded Content will be considered personal information and treated
        as such under the terms outlined in the Privacy Policy, including any
        rights that More Readings and its affiliates may have in and to the
        personal information. You represent and warrant that:
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          You own or control all rights in and to Uploaded Content posted by you
          and have the right to grant More Readings and its affiliates the
          rights outlined in the Privacy Policy;
        </li>
        <li className={styles.li}>
          All Uploaded Content you post do not and will not infringe or violate
          the rights of any third party; and
        </li>
        <li className={styles.li}>
          All Uploaded Content you post do and will comply with these Terms.
        </li>
      </ul>

      <p className={styles.p}>
        You understand and acknowledge that you are responsible for any Uploaded
        Content you post, and you, not Tacit Social LLC. have full
        responsibility for such Uploaded Content, including its legality,
        reliability, accuracy, and appropriateness.
      </p>

      <p className={styles.p}>
        We are not responsible, or liable to any third party, for the content or
        accuracy of any Uploaded Content posted by you or any other user of the
        Services.
      </p>

      <p className={styles.p}>
        While we're not required to do so, we may access, review, screen, and
        delete your content at any time and for any reason, including to provide
        and develop the Services or if we think your content violates these
        Terms. You alone, though, remain responsible for the content you create,
        upload, post, send, or store through the Service.
      </p>

      <h2 className={styles.h2}>
        Prohibition of Illegal Photographic Materials
      </h2>

      <p className={styles.p}>
        Prohibited Content: The use of More Readings requires compliance with
        local, national, and international laws. Users are strictly prohibited
        from uploading, sharing, or disseminating any photographic material that
        is illegal under any applicable laws. This includes, but is not limited
        to, images that are pornographic, abusive, violating privacy, promoting
        illegal activities, or infringing on intellectual property rights. 1.2
        User Responsibility: Users are solely responsible for ensuring that the
        content they upload to More Readings complies with all applicable laws
        and regulations. By using More Readings, users agree not to upload any
        content that could be considered illegal or harmful
      </p>

      <p className={styles.p}>
        Enforcement and Right to Refuse Service: More Readings reserves the
        right to review and monitor content for compliance with this policy. In
        cases where illegal or inappropriate content is identified or reported,
        More Readings reserves the right to take appropriate actions. This may
        include removing the content, suspending or terminating the user's
        account, and/or reporting the matter to the relevant authorities. More
        Readings further reserves the right to refuse service to any user at its
        sole discretion, without prior notice, for any or no reason.
      </p>

      <p className={styles.p}>
        Regional Compliance: Users are reminded that legal standards and
        regulations regarding photographic materials can vary significantly
        across different regions and jurisdictions. It is the responsibility of
        each user to be aware of and comply with the laws governing their region
        or any region in which they access or use More Readings.
      </p>

      <p className={styles.p}>
        Reporting Violations: More Readings encourages the community to report
        any instances of illegal or inappropriate content to
        hello@support.morereadings.me. More Readings will take appropriate
        measures in response to such reports in accordance with this term and
        our overall Terms of Service.
      </p>

      <h2 className={styles.h2}>AI-Generated Content Disclaimer</h2>

      <p className={styles.p}>
        AI Assistance: More Readings employs advanced artificial intelligence
        (AI) technologies to generate text, speculation, metrics, and images for
        users. This AI functionality is designed to enhance the user experience
        by providing creative, engaging, and entertaining content.
      </p>

      <p className={styles.p}>
        Purpose of Content: The content generated by More Readings through AI
        assistance is intended solely for entertainment purposes. Users should
        not rely on this content for accuracy, completeness, or usefulness in
        decision-making. More Readings makes no representations or warranties of
        any kind, express or implied, about the reliability, accuracy,
        suitability, or availability of any content generated by AI.
      </p>

      <p className={styles.p}>
        No Guarantee of Validity: While More Readings strives to provide a novel
        and engaging experience, it acknowledges that AI-generated content may
        occasionally produce unexpected, speculative, or inaccurate results.
        Users agree to use More Readings with the understanding that all
        AI-generated content is provided on an “as is” and “as available” basis,
        without warranty of any kind.
      </p>

      <p className={styles.p}>
        Limitation of Liability: More Readings and its developers disclaim all
        liability for any loss, damage, injury, or expense however caused,
        arising from the use of or reliance upon, directly or indirectly, the
        AI-generated content provided by the app. Users acknowledge that the
        interpretation and use of any AI-generated content are at their own
        risk.
      </p>

      <p className={styles.p}>
        No Financial Advice: More Readings does not provide financial advice,
        and any financial-related opinions generated by AI are not to be taken
        seriously. Users should not base any financial decisions on AI-generated
        content.
      </p>

      <p className={styles.p}>
        User Discretion Advised: Users are advised to exercise discretion and
        critical judgment when interacting with AI-generated content. More
        Readings encourages users to enjoy the innovative and entertaining
        experiences offered by AI with an understanding of its limitations and
        speculative nature.
      </p>

      <p className={styles.p}>
        Reporting Inaccuracies: More Readings values user feedback and strives
        to improve the AI's accuracy and relevance. Users are encouraged to
        report any concerns or inaccuracies in AI-generated content through
        hello@support.morereadings.me so that More Readings can continue to
        enhance the user experience.
      </p>

      <h2 className={styles.h2}>Purchases</h2>

      <p className={styles.p}>
        We offer products and services for purchase through the Apple App Store,
        Google Play, Shopify and other external services authorized by More
        Readings (each, an “external service”). Any purchase made on an external
        service will be processed through your account (“External Service
        Account”) on that external service in accordance with the terms
        disclosed to you at the time of purchase and the general terms
        applicable to your External Service Account. Some external services may
        charge you sales tax, depending on where you live, which may at times
        change.
      </p>

      <p className={styles.p}>
        Any offer for any product or service made on our Services is void where
        prohibited.
      </p>

      <h2 className={styles.h2}>IN-APP FEES</h2>

      <p className={styles.p}>
        The Company may, now or in the future, charge subscription or service
        fees for the use of the Services or certain Service features. You agree
        to pay to the Company all fees for products or services purchased on or
        through the Services under your Account at the then-current prices and
        rates (including any applicable taxes), and to abide by the Company's
        payment terms in effect at the time of such purchase. The Company may
        change the fees for use of the Services or any Service features or add
        new fees or charges, at any time. For any change in fees for products or
        services purchased under your Account, the Company will send you a
        notice of such change in advance of the time such change takes effect
        (via a message on or through the Services).
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          To request a refund from More Readings, please contact
          hello@support.morereadings.me
        </li>
      </ul>

      <h2 className={styles.h2}>SUBSCRIPTIONS</h2>

      <p className={styles.p}>
        You may have access to a free trial period for a subscription. If you
        sign up for a free trial and do not cancel before the trial ends, your
        trial may convert into a paid subscription and the Payment Method you
        set up in your External Service Account will be automatically charged.
        You will continue to be charged until the cancel the subscription
        through the External Service Account methods shared above in the
        Cancellations and refunds section. If you have signed up for a free
        trial in the subscription through the Apple Store or Google Play Store
        previously, you will not be eligible for another free trial--in this
        case you will be automatically signed up for a paid subscription and
        charged as described in these terms.
      </p>
      <p className={styles.p}>
        If you purchase a subscription, it will automatically renew at the price
        you agreed to when subscribing until you cancel, in accordance with the
        terms disclosed to you at the time of purchase and the general terms
        applicable to your External Service Account.
      </p>

      <p className={styles.p}>
        To cancel your subscription, you must log into your External Service
        Account and follow the relevant instructions.
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          To cancel your subscription with More Readings, please contact
          hello@support.morereadings.me
        </li>
      </ul>

      <p className={styles.p}>
        If you cancel your subscription, you will continue to have access to
        your subscription benefits until the end of your subscription period, at
        which point it will expire. Because our Services may be used without a
        subscription, canceling your subscription does not remove your profile
        from our Services. If you wish to fully delete your profile, you must do
        so as outlined in our privacy policy.
      </p>

      <h2 className={styles.h2}>ONLINE STORES</h2>

      <p className={styles.p}>
        As part of the Services, the Company may, now or in the future, make
        certain products or services available exclusively online through the
        Websites (the “Online Stores”). These products or services may have
        limited quantities and are subject to return or exchange only according
        to our policies outlined in our FAQ.
      </p>

      <p className={styles.p}>
        We try to ensure that our online pricing and product information are
        accurate and complete. However, from time to time, mistakes occur, and
        we reserve the right to correct any errors, inaccuracies, or omissions,
        and to change or update information or cancel orders if any information
        in the Service is inaccurate at any time without prior notice (including
        after you have submitted your order).
      </p>
      <p className={styles.p}>
        We reserve the right to limit the quantities of, change prices or
        descriptions of, or discontinue any products or services that we offer
        at any time, without notice. We undertake no obligation to update, amend
        or clarify information in the Service, including without limitation,
        pricing information, except as required by law. We do not warrant that
        the quality of any products, services, information, or other material
        purchased or obtained by you will meet your expectations.
      </p>
      <p className={styles.p}>
        We reserve the right to limit, block, or cancel any order you place with
        us. We may exercise this right on a case-by-case basis and at our sole
        discretion. If we make a change to or cancel an order, we may attempt to
        notify you by contacting the e-mail, billing address, and/or phone
        number provided at the time the order was made.
      </p>

      <h2 className={styles.h2}>
        User comments, feedback, and other submissions
      </h2>

      <p className={styles.p}>
        If you send us ideas, suggestions, proposals, feedback, plans, or other
        materials, whether online, by email, by postal mail, or otherwise
        (“Comments”), you agree that we may, at any time, without restriction,
        edit, copy, publish, distribute, translate, and otherwise use such
        Comments. We are and shall be under no obligation to maintain any
        comments in confidence; to pay compensation for any comments; or to
        respond to any comments.
      </p>
      <p className={styles.p}>
        You agree that your Comments will not violate any right of a
        third-party, including copyright, trademark, privacy, or other personal
        or proprietary right. You further agree that your Comments will not
        contain libelous or otherwise unlawful, abusive, or obscene material, or
        contain any computer virus or other malware that could in any way affect
        the operation of the Services. You may not use a false e-mail address,
        pretend to be someone other than yourself, or otherwise mislead us or
        third parties as to the origin of any comments. You are solely
        responsible for any Comments you make and their accuracy. We take no
        responsibility and assume no liability for any Comments you send us.
      </p>

      <h2 className={styles.h2}>Terminating or suspending an account</h2>

      <p className={styles.p}>
        We may, for any reason and in our sole discretion, suspend, limit, or
        terminate your Account and your access to or use of the Services,
        without notice or liability to you. You may terminate your Account and
        stop using the Services at any time by notifying us at
        hello@support.morereadings.me and providing sufficient information for
        us to verify your identity. Upon the termination of your Account, you
        must cease all use of the Services. Termination of your Account will not
        affect any of our rights or your obligations arising under these Terms
        prior to such termination. Provisions of these Terms that, by their
        nature, should survive termination of your Account will survive such
        termination.
      </p>

      <h2 className={styles.h2}>
        Procedure for alleging copyright infringement
      </h2>

      <p className={styles.p}>
        Pursuant to the Digital Millennium Copyright Act of 1998 (the “DMCA”),
        copyright owners who allege infringement of their copyrights on the
        Website and/or Apps may file a notice of infringement with the Company
        by contacting the designated agent using the information provided below:
      </p>
      <ul className={styles.ul}>
        <li className={styles.li}>Email: hello@support.morereadings.me</li>
      </ul>

      <p className={styles.p}>
        Filing a notice of infringement with the federal government of the
        United States requires compliance with the requirements specified in
        Title II of the DMCA. The text of this statute can be found at the U.S.
        Copyright Office website, copyright.gov.
      </p>

      <h2 className={styles.h2}>Reliance on information posted</h2>

      <p className={styles.p}>
        The information presented through the Services is made available solely
        for general information purposes. We do not warrant the accuracy,
        completeness, or usefulness of this information. Any reliance you place
        on such information is strictly at your own risk. We disclaim all
        liability and responsibility arising from any reliance placed on such
        materials by you or any other user of the Services or by anyone who may
        be informed of information presented through the Services.
      </p>

      <p className={styles.p}>
        The Services may include content provided by third parties, including
        materials provided by other users, bloggers and third-party licensors,
        syndicators, aggregators, and/or reporting services. All statements
        and/or opinions expressed in these materials, and all articles and
        responses to questions and other content, other than the content
        provided by More Readings, are solely the opinions and the
        responsibility of the person or entity providing those materials. These
        materials do not necessarily reflect the opinion of More Readings. We
        are not responsible, or liable to you or any third party, for the
        content or accuracy of any materials provided by any third parties.
      </p>

      <h2 className={styles.h2}>Privacy</h2>

      <p className={styles.p}>
        Please review our Privacy Policy for details on how we collect, use,
        store, and disclose information in connection with the Services. You can
        find our Privacy Policy here:{" "}
        <a href="https://morereadings.com/policies">
          morereadings.com/policies
        </a>
        . By using the Services, you consent to all actions taken by us with
        respect to your information in compliance with our Privacy Policy.
      </p>

      <h2 className={styles.h2}>
        Linking to the website and social media features
      </h2>

      <p className={styles.p}>
        You may link to the Services only in a way that is fair and legal and
        does not seek to damage More Readings's reputation or take advantage of
        it. You must not
      </p>

      <ul className={styles.ul}>
        <li className={styles.li}>
          Establish a link in such a way as to suggest any form of association,
          approval, or endorsement on our part without our express written
          consent.
        </li>
        <li className={styles.li}>
          Cause the Website or portions of it to be displayed, or appear to be
          displayed by, any other website except those owned by you or as
          permitted in writing by More Readings. Examples of the display of the
          Website include framing, deep linking, or in-line linking.
        </li>
        <li className={styles.li}>
          Otherwise take any action with respect to the materials on the
          Services that is inconsistent with any other provision of these Terms.
        </li>
      </ul>

      <h2 className={styles.h2}>Links from the Services</h2>

      <p className={styles.p}>
        If the Services contain links to other websites provided by third
        parties (“Linked Sites”), these links are provided for your convenience
        only. Our inclusion of such links does not imply our endorsement of any
        Linked Sites or any association with their owners or operators, and we
        expressly disclaim responsibility and liability of any such Linked
        Sites. We have no control over the contents of Linked Sites and accept
        no responsibility for them. If you decide to access any Linked Sites,
        you do so entirely at your own risk and subject to the terms and
        conditions of use for such Linked Sites. Any interactions you have with
        Linked Sites are between you and such site, and you agree that we are
        not liable for any damage or loss you may suffer as a result of any
        interactions with any Linked Sites or any claims that you may have
        against Linked Sites.
      </p>

      <h2 className={styles.h2}>Geographic restrictions</h2>

      <p className={styles.p}>
        We make no claims that the Services are accessible or appropriate
        outside of the United States. Access to the Services may not be legal by
        certain persons or in certain countries. If you access the Services from
        outside the United States, you do so at your own risk and initiative and
        are responsible for compliance with local laws.
      </p>

      <h2 className={styles.h2}>Disclaimer of warranties</h2>

      <p className={styles.p}>
        WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
        DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL
        MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER APPS, DATA,
        OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICES OR ITEMS
        OBTAINED THROUGH THE SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL
        POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
      </p>

      <p className={styles.p}>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY
        UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES AND ALL INFORMATION,
        PRODUCTS, AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED
        IN OR ACCESSIBLE FROM THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE
        PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES
        OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY
        PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION
        WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
        ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE
        FOREGOING, NEITHER THE COMPANY, NOR ANYONE ASSOCIATED WITH THE COMPANY,
        REPRESENTS OR WARRANTS THAT THE SERVICES WILL BE ACCURATE, RELIABLE,
        ERROR-FREE, OR UNINTERRUPTED, THAT ERRORS IN THE SERVICES WILL BE
        CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE MAY
        NOT CONTAIN VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES
        WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
      </p>

      <p className={styles.p}>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY HEREBY
        DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
        STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF
        MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </p>

      <h2 className={styles.h2}>Limitation on liability</h2>

      <p className={styles.p}>
        IN NO EVENT WILL More Readings, ITS AFFILIATES, OR ITS OR THEIR
        LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS
        BE LIABLE FOR DAMAGES OF ANY KIND. WE WILL NOT BE LIABLE FOR DAMAGES
        UNDER ANY LEGAL THEORY. WE WILL NOT BE LIABLE FOR DAMAGES ARISING OUT OF
        OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICES OR ANY
        INFORMATION, PRODUCTS, OR OTHER CONTENT (INCLUDING THAT OF THIRD
        PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICES, INCLUDING ANY
        DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR
        PUNITIVE DAMAGES OF ANY KIND. More Readings IS NOT LIABLE FOR DAMAGES
        INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
        EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
        OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA. WE
        ARE NOT LIABLE FOR ANY DAMAGES WHETHER CAUSED BY TORT (INCLUDING
        NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
      </p>

      <p className={styles.p}>
        IN ADDITION TO AND WITHOUT LIMITING ANY OF THE FOREGOING, WE WILL HAVE
        NO LIABILITY FOR ANY FAILURE OR DELAY RESULTING FROM ANY CONDITION
        BEYOND OUR REASONABLE CONTROL. CONDITIONS BEYOND OUR REASONABLE CONTROL
        INCLUDE, WITHOUT LIMITATION, LABOR CONDITIONS, POWER FAILURES, INTERNET
        DISTURBANCES, OR SERVICES OR SYSTEMS CONTROLLED BY THIRD PARTIES, ACTS
        OF THE GODS, AND ACTS OF TERRORISM.
      </p>

      <p className={styles.p}>
        THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </p>

      <h2 className={styles.h2}>Indemnification</h2>
      <p className={styles.p}>
        Should you violate these Terms; use the Services, including, but not
        limited to, your Social Content, any use of the Services' content,
        services, and products other than as expressly authorized in these
        Terms; or use any information obtained from the Services in violation of
        these Terms, you agree to hold harmless, indemnify and (at our request)
        defend More Readings, its affiliates, licensors, and service providers,
        and its and their respective officers, directors, employees,
        contractors, agents, licensors, suppliers, successors, and assigns from
        and against any claims, liabilities, damages, judgments, awards, losses,
        costs, expenses, and fees (including reasonable attorneys' fees). We
        reserve the right to assume the exclusive defense and control of any
        matter subject to indemnification by you, in which event you will
        cooperate in asserting any available defenses.
      </p>

      <p className={styles.p}>
        California Residents: you expressly waive CA Civil Code §1542, which
        states: “A general release does not extend to claims which the creditor
        does not know or suspect to exist in his favor at the time of executing
        the release, which if known by him must have materially affected his
        settlement with the debtor.”
      </p>

      <h2 className={styles.h2}>Governing law</h2>

      <p className={styles.p}>
        All matters relating to the Services and these Terms and any dispute or
        claim arising therefrom or related thereto (in each case, including
        non-contractual disputes or claims), will be governed by and construed
        in accordance with the internal laws of the State of Delaware without
        giving effect to any choice or conflict of law provision or rule
        (whether of the State of Delaware or any other jurisdiction).
      </p>

      <h2 className={styles.h2}>Dispute resolution</h2>

      <p className={styles.p}>
        DISPUTE RESOLUTION BY BINDING ARBITRATION; JURY TRIAL WAIVER; CLASS
        ACTION WAIVER. For any and all controversies, disputes, demands, claims,
        or causes of action between you and us (including the interpretation and
        scope of this Section and the arbitrability of the controversy, dispute,
        demand, claim, or cause of action) relating to the Services or these
        Terms (as well as any related or prior agreement that you may have had
        with us), you and we agree to resolve any such controversy, dispute,
        demand, claim, or cause of action exclusively through binding and
        confidential arbitration. Arbitration is a legal process to resolve
        disputes which is outside of the court system. In the event of any such
        controversy, dispute, demand, claim, or cause of action, the complaining
        party must notify the other party in writing. Within 30 days of such
        notice, both parties agree to use reasonable efforts to attempt to
        resolve the dispute in good faith. If both parties do not resolve the
        dispute within 30 days after such notice, the complaining party must
        seek remedies exclusively through arbitration. The demand for
        arbitration must be made within a reasonable time after the controversy,
        dispute, demand, claim, or cause of action in question arose, and in no
        event may it be made after two years from when the complaining party
        knew or should have known of the controversy, dispute, demand, claim, or
        cause of action.
      </p>

      <p className={styles.p}>
        The arbitration will take place in the federal judicial district of your
        residence. As used in this section, “we” and “us” mean the Company and
        its subsidiaries, affiliates, predecessors, successors, and assigns and
        all its and their respective employees, officers, directors, agents, and
        representatives. In addition, “we” and “us” include any third party
        providing any product, service, or benefit in connection with the
        Services or these Terms (as well as any related or prior agreement that
        you may have had with us) if such third party is named as a co-party
        with us in any controversy, dispute, demand, claim, or cause of action
        subject to this section.
      </p>

      <p className={styles.p}>
        Arbitration will be subject to the Federal Arbitration Act and not any
        state arbitration law. The arbitration will be conducted before one
        commercial arbitrator from the American Arbitration Association (“AAA”)
        with substantial experience in resolving commercial contract disputes.
        As modified by these Terms, and unless otherwise agreed upon by the
        parties in writing, the arbitration will be governed by the AAA's
        Commercial Arbitration Rules and, if the arbitrator deems them
        applicable, the Supplementary Procedures for Consumer Related Disputes
        (collectively, the “Rules and Procedures”).
      </p>

      <p className={styles.p}>
        You should review this provision carefully. To the extent permitted by
        applicable law, you are agreeing to resolve disputes through arbitration
        and waiving your right to do so through a court and to assert or defend
        your rights in court, EXCEPT for matters that you file in small claims
        court in the state or municipality of your residence or as otherwise
        provided in the Rules and Procedures within the jurisdictional limits of
        the small claims court and as long as such matter is only pending in
        that court. Additionally, notwithstanding this agreement to arbitrate,
        either party may seek emergency equitable relief in federal court if it
        has jurisdiction or, if it does not, in a state court located in the
        federal judicial district of your residence to maintain the status quo
        pending arbitration, and each party hereby agrees to submit to the
        exclusive personal jurisdiction of the courts located within the federal
        judicial district of your residence for such purpose. A request for
        interim measures will not be deemed a waiver of the obligation to
        arbitrate. Your rights will be determined by a NEUTRAL ARBITRATOR and
        NOT a judge or jury. You are entitled to a FAIR HEARING, BUT the
        arbitration procedures may be SIMPLER AND MORE LIMITED THAN RULES
        APPLICABLE IN COURT. Arbitrators' decisions are as enforceable as any
        court order and are subject to VERY LIMITED REVIEW BY A COURT.
      </p>

      <p className={styles.p}>
        You and we must abide by the following rules: (A) ANY CLAIMS BROUGHT BY
        YOU OR US MUST BE BROUGHT IN THE PARTY'S INDIVIDUAL CAPACITY, AND NOT AS
        A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
        PROCEEDING; (B) THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE
        PERSON'S CLAIMS, MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
        REPRESENTATIVE OR CLASS PROCEEDING, AND MAY NOT AWARD CLASS-WIDE RELIEF;
        (c) we will pay as much of your filing and hearing fees in connection
        with the arbitration as required by the Rules and Procedures and/or as
        the arbitrator deems necessary to prevent the arbitration from being
        cost-prohibitive as compared to the cost of litigation, (d) we also
        reserve the right, in our sole and exclusive discretion, to assume
        responsibility for any or all of the costs of the arbitration; (e) the
        arbitrator will honor claims of privilege and privacy recognized at law;
        (f) the arbitration will be confidential, and neither you nor we may
        disclose the existence, content, or results of any arbitration, except
        as may be required by applicable law or for purposes of enforcement of
        the arbitration award; (g) subject to the limitation of liability
        provisions of these Terms, the arbitrator may award any individual
        relief or individual remedies that are expressly permitted by applicable
        law; and (h) each party will pay its own attorneys' fees and expenses,
        unless there is a statutory provision that requires the prevailing party
        to be paid its fees and litigation expenses and the arbitrator awards
        such attorneys' fees and expenses to the prevailing party, and, in such
        instance, the fees and costs awarded will be determined by the
        applicable law.
      </p>

      <p className={styles.p}>
        This section will survive termination of these Terms as well as any
        voluntary payment of any debt in full by you or any bankruptcy by you or
        us. With the exception of subparts (a) and (b) of this section
        (prohibiting arbitration on a class or collective basis), if any part of
        this arbitration provision is deemed to be invalid, unenforceable, or
        illegal, or otherwise conflicts with the Rules and Procedures, then the
        balance of this arbitration provision will remain in effect and will be
        construed in accordance with its terms as if the invalid, unenforceable,
        illegal, or conflicting part was not contained herein. If, however,
        either subpart (a) or (b) of this section is found to be invalid,
        unenforceable, or illegal, then the entirety of this arbitration
        provision will be null and void, and neither you nor we will be entitled
        to arbitration. If for any reason a claim proceeds in court rather than
        in arbitration, the dispute will be exclusively brought in federal court
        if it has jurisdiction or, if it does not, in a state court located in
        the federal judicial district of your residence.
      </p>

      <p className={styles.p}>
        If you wish to opt out of this arbitration provision, you must notify us
        of your election in writing within 30 days of the date that you first
        became subject to this arbitration provision or within 30 days of the
        effective date of any material change to these Terms by sending a
        written notice to us by certified mail at the following address: More
        Readings, Attn: Arbitration Opt-Out, More Readings, 701 Tillery Street
        Unit 12-1836, Austin, Texas 78702, United States. Your opt-out notice
        must include your name, address, phone number, and email address.
      </p>
      <p className={styles.p}>
        For more information on the AAA, the Rules and Procedures, or the
        process for filing an arbitration claim, you may call the AAA at
        800-778-7879 or visit the AAA website at <a href="adr.org">adr.org</a>.
      </p>

      <h2 className={styles.h2}>LIMITATION ON TIME TO FILE CLAIMS</h2>

      <p className={styles.p}>
        ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
        THE SERVICES OR THESE TERMS MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER
        THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
        PERMANENTLY BARRED.
      </p>

      <h2 className={styles.h2}>Waiver and severability</h2>
      <p className={styles.p}>
        No waiver of by the Company of any term or condition set forth in these
        Terms will be deemed a further or continuing waiver of such term or
        condition or a waiver of any other term or condition, and any failure of
        the Company to assert a right or provision under these Terms will not
        constitute a waiver of such right or provision.
      </p>
      <p className={styles.p}>
        If any provision of these Terms is held by a court or other tribunal of
        competent jurisdiction to be invalid, illegal, or unenforceable for any
        reason, such provision will be eliminated or limited to the minimum
        extent such that the remaining provisions of the Terms will continue in
        full force and effect.
      </p>
      <h2 className={styles.h2}>Notices</h2>
      <p className={styles.p}>
        We will send all notices and other communications regarding the Services
        to you using the contact information you provided during your Account
        registration, as it may be updated by you from time to time or by
        posting the notice on the Services. You may change your contact
        information by revising your Account profile through the Services.
        Except as otherwise provided by applicable law, you will be considered
        to have received a notice from us regarding the Services when we send it
        to you via the contact information we have in our records for you or
        when we post such notice on the Services. All notices to us that are
        intended to have a legal effect must be in writing and delivered by a
        means evidenced by a delivery receipt, to the following address: More
        Readings, 701 Tillery Street Unit 12-1836, Austin, Texas 78702, United
        States. All such notices are deemed effective upon documented receipt by
        us.
      </p>
      <h2 className={styles.h2}>Entire agreement and other general terms</h2>
      <p className={styles.p}>
        These Terms and any policies or operating rules posted by us on the
        Apps. Websites or in respect to the Service constitute the entire
        agreement between you and the Company with respect to the Services and
        supersede all prior and contemporaneous understandings, agreements,
        representations, and warranties, both written and oral, with respect to
        the Services. These Terms and any rights hereunder may not be
        transferred or assigned by you without our prior written consent but may
        be assigned by us without restriction and without your prior consent.
        Any attempted transfer or assignment by you without our prior written
        consent will be null and void. No agency, joint venture, partnership,
        trust, or employment relationship is created between you and us by way
        of these Terms. The section headings used herein are for convenience
        only and will not be given any legal import.
      </p>

      <h2 className={styles.h2}>Contact us</h2>
      <p className={styles.p}>
        If you have any questions regarding these Terms or the Services, please
        contact us at hello@support.morereadings.me
      </p>
    </div>
  );
};
