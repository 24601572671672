import AriesPng from "app/assets/horoscopeSign/lunarAries.png";
import AriesMp4 from "app/assets/horoscopeSign/lunarAries.mp4";
import TaurusPng from "app/assets/horoscopeSign/lunarTaurus.png";
import TaurusMp4 from "app/assets/horoscopeSign/lunarTaurus.mp4";
import GeminiPng from "app/assets/horoscopeSign/lunarGemini.png";
import GeminiMp4 from "app/assets/horoscopeSign/lunarGemini.mp4";
import CancerPng from "app/assets/horoscopeSign/lunarCancer.png";
import CancerMp4 from "app/assets/horoscopeSign/lunarCancer.mp4";
import LeoPng from "app/assets/horoscopeSign/lunarLeo.png";
import LeoMp4 from "app/assets/horoscopeSign/lunarLeo.mp4";
import VirgoPng from "app/assets/horoscopeSign/lunarVirgo.png";
import VirgoMp4 from "app/assets/horoscopeSign/lunarVirgo.mp4";
import LibraPng from "app/assets/horoscopeSign/lunarLibra.png";
import LibraMp4 from "app/assets/horoscopeSign/lunarLibra.mp4";
import ScorpioPng from "app/assets/horoscopeSign/lunarScorpio.png";
import ScorpioMp4 from "app/assets/horoscopeSign/lunarScorpio.mp4";
import SagittariusPng from "app/assets/horoscopeSign/lunarSagittarius.png";
import SagittariusMp4 from "app/assets/horoscopeSign/lunarSagittarius.mp4";
import CapricornPng from "app/assets/horoscopeSign/lunarCapricorn.png";
import CapricornMp4 from "app/assets/horoscopeSign/lunarCapricorn.mp4";
import AquariusPng from "app/assets/horoscopeSign/lunarAquarius.png";
import AquariusMp4 from "app/assets/horoscopeSign/lunarAquarius.mp4";
import PiscesPng from "app/assets/horoscopeSign/lunarPisces.png";
import PiscesMp4 from "app/assets/horoscopeSign/lunarPisces.mp4";

export const enum HoroscopeSignDictionaryEnum {
  ARIES = "aries",
  TAURUS = "taurus",
  GEMINI = "gemini",
  CANCER = "cancer",
  LEO = "leo",
  VIRGO = "virgo",
  LIBRA = "libra",
  SCORPIO = "scorpio",
  SAGITTARIUS = "sagittarius",
  CAPRICORN = "capricorn",
  AQUARIUS = "aquarius",
  PISCES = "pisces",
}
export const horoscopeSignDictionary = {
  [HoroscopeSignDictionaryEnum.ARIES]: {
    name: HoroscopeSignDictionaryEnum.ARIES,
    image: AriesPng,
    video: AriesMp4,
    description:
      "An Aries is brave, energetic, and loves being a leader, but that’s not the whole story! Your moon sign and rising sign also show how you feel and act, so there’s a lot more to you than just being an Aries.",
  },
  [HoroscopeSignDictionaryEnum.TAURUS]: {
    name: HoroscopeSignDictionaryEnum.TAURUS,
    image: TaurusPng,
    video: TaurusMp4,
    description:
      "A Taurus is loyal, calm, and loves feeling safe and comfy, but that’s not the whole story! Your moon sign and rising sign also help show how you feel and act, so there’s a lot more to you than just being a Taurus.",
  },
  [HoroscopeSignDictionaryEnum.GEMINI]: {
    name: HoroscopeSignDictionaryEnum.GEMINI,
    image: GeminiPng,
    video: GeminiMp4,
    description:
      "A Gemini is curious, fun, and loves talking with friends, but that’s not the whole story! Your moon sign and rising sign also help explain how you feel and act, so there’s more to you than just being a Gemini.",
  },
  [HoroscopeSignDictionaryEnum.CANCER]: {
    name: HoroscopeSignDictionaryEnum.CANCER,
    image: CancerPng,
    video: CancerMp4,
    description:
      "A Cancer is caring, sensitive, and loves being close to family, but that’s not the whole story! Your moon sign and rising sign also help show how you feel and act, so there’s more to you than just being a Cancer.",
  },
  [HoroscopeSignDictionaryEnum.LEO]: {
    name: HoroscopeSignDictionaryEnum.LEO,
    image: LeoPng,
    video: LeoMp4,
    description:
      "A Leo is confident, creative, and loves being in the spotlight, but that’s not the whole story! Your moon sign and rising sign also help explain how you feel and act, so there’s more to you than just being a Leo.",
  },
  [HoroscopeSignDictionaryEnum.VIRGO]: {
    name: HoroscopeSignDictionaryEnum.VIRGO,
    image: VirgoPng,
    video: VirgoMp4,
    description:
      "A Virgo is helpful, organized, and loves making things better, but that’s not the whole story! Your moon sign and rising sign also help show how you feel and act, so there’s more to you than just being a Virgo.",
  },
  [HoroscopeSignDictionaryEnum.LIBRA]: {
    name: HoroscopeSignDictionaryEnum.LIBRA,
    image: LibraPng,
    video: LibraMp4,
    description:
      "A Libra is charming, fair, and loves having harmony with others, but that’s not the whole story! Your moon sign and rising sign also help show how you feel and act, so there’s more to you than just being a Libra.",
  },
  [HoroscopeSignDictionaryEnum.SCORPIO]: {
    name: HoroscopeSignDictionaryEnum.SCORPIO,
    image: ScorpioPng,
    video: ScorpioMp4,
    description:
      "A Scorpio is passionate, determined, and loves keeping secrets, but that’s not the whole story! Your moon sign and rising sign also help show how you feel and act, so there’s more to you than just being a Scorpio.",
  },
  [HoroscopeSignDictionaryEnum.SAGITTARIUS]: {
    name: HoroscopeSignDictionaryEnum.SAGITTARIUS,
    image: SagittariusPng,
    video: SagittariusMp4,
    description:
      "A Sagittarius is adventurous, free-spirited, and loves learning new things, but that’s not the whole story! Your moon sign and rising sign also help explain how you feel and act, so there’s more to you than just being a Sagittarius.",
  },
  [HoroscopeSignDictionaryEnum.CAPRICORN]: {
    name: HoroscopeSignDictionaryEnum.CAPRICORN,
    image: CapricornPng,
    video: CapricornMp4,
    description:
      "A Capricorn is hardworking, responsible, and loves setting goals, but that’s not the whole story! Your moon sign and rising sign also help show how you feel and act, so there’s more to you than just being a Capricorn.",
  },
  [HoroscopeSignDictionaryEnum.AQUARIUS]: {
    name: HoroscopeSignDictionaryEnum.AQUARIUS,
    image: AquariusPng,
    video: AquariusMp4,
    description:
      "An Aquarius is unique, friendly, and loves thinking about big ideas, but that’s not the whole story! Your moon sign and rising sign also help explain how you feel and act, so there’s more to you than just being an Aquarius.",
  },
  [HoroscopeSignDictionaryEnum.PISCES]: {
    name: HoroscopeSignDictionaryEnum.PISCES,
    image: PiscesPng,
    video: PiscesMp4,
    description:
      "A Pisces is dreamy, kind, and loves helping others, but that’s not the whole story! Your moon sign and rising sign also help show how you feel and act, so there’s more to you than just being a Pisces.",
  },
};
